import { IUserModel } from "@/types";
import Vue from "vue";
import { userMapActions } from "@/store/modules/user";

interface SelectOption {
  title: string;
  text: string;
}
interface Messages {
  title: string;
  contacts: SelectOption[];
}

interface IData {
  messages: Messages[];
}
export default Vue.extend({
  data(): IData {
    return {
      messages: []
    };
  },

  mounted() {
    this.getContactDetails();
  },

  methods: {
    ...userMapActions(["getContactUsDetails"]),

    async getContactDetails() {
      try {
        const response = await this.getContactUsDetails({
          atlasAdmin__exists: true,
          role__in: "underwriting,billing"
        });
        this.formatContactData(response);
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      }
    },

    formatContactData(users: IUserModel[]) {
      const underwritingData: { title: string; contacts: any[] } = {
        title: "Underwriting",
        contacts: [
          {
            title: "Underwriting",
            text: "888-786-0003",
            type: "phone"
          }
        ]
      };

      const billingData: { title: string; contacts: any[] } = {
        title: "Billing",
        contacts: [
          {
            title: "Billing",
            text: "888-786-0003"
          }
        ]
      };

      users.forEach((user: IUserModel) => {
        const userData = {
          title: `${user.firstName} ${user.lastName}`,
          text: user.phone?.extension || "",
          type: "phone"
        };
        if (user.role === "underwriting") {
          underwritingData.contacts.unshift(userData);
        } else if (user.role === "billing") {
          billingData.contacts.push(userData);
        }
      });
      this.messages = [billingData, underwritingData];
    }
  }
});
