export const displayXML = (rawXML: any) => {
  if (rawXML) {
    const blob = new Blob([rawXML], { type: "text/xml" });
    const url = URL.createObjectURL(blob);
    window.open(url);
    URL.revokeObjectURL(url);
  }
};

export const combineXMLs = (xmlStrings: string[]) => {
  const parser = new DOMParser();
  const serializer = new XMLSerializer();

  if (!xmlStrings.length) return;
  // Parse the first XML as the base document
  const baseDoc = parser.parseFromString(xmlStrings[0], "application/xml");
  const baseRoot = baseDoc.documentElement;

  // Merge other XML documents into the base
  for (let i = 1; i < xmlStrings.length; i++) {
    const currentDoc = parser.parseFromString(xmlStrings[i], "application/xml");
    const currentRoot = currentDoc.documentElement;

    // Append child nodes from the current document to the base document
    Array.from(currentRoot.childNodes).forEach(node => {
      const importedNode = baseDoc.importNode(node, true);
      baseRoot.appendChild(importedNode);
    });
  }

  // Serialize the merged document back to a string
  const combinedXML = serializer.serializeToString(baseDoc);
  return combinedXML;
};
