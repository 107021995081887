
import { formatCurrencyFloat } from "@/plugins/filters";
import { get } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "PolicyCoverage",
  props: {
    data: {
      type: Object
    }
  },

  computed: {
    coverageData(): Record<string, any> {
      return get(this.data, "coverageForUI", {});
    },
    DFCoverage(): any[] {
      return this.coverageData["DF"];
    },
    PLCoverage(): Record<string, any> {
      return this.coverageData["PL"];
    },
    PL_HOCoverage(): Record<string, any> {
      return this.policyType === "PL" ? this.PLCoverage : this.HOCoverage;
    },
    HOCoverage(): Record<string, any> {
      return this.coverageData["HO"];
    },
    policyType(): string {
      return get(this.data, "PolicyType", "");
    },
    hasICL(): boolean {
      return Boolean(get(this.data, "hasICL"));
    },
    policyIsHomeOwner(): boolean {
      return Boolean(this.policyType === "HO");
    },
    hasICLCoverageAmount() {
      return "-";
    },
    hasICLPreAmt(): string {
      return formatCurrencyFloat(get(this.data, "ICLAmt"));
    }
  }
});
