
import { formatCurrencyFloat } from "@/plugins/filters";
import { get } from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "PolicyTotal",
  props: {
    policyInquiryData: {
      type: Object
    }
  },
  computed: {
    coverageSTIAmount(): string {
      return "-";
    },
    coverageSURAmount(): string {
      return "-";
    },
    premiumST1Amount(): string {
      return formatCurrencyFloat(get(this.policyInquiryData, "ST1Amt", "-"));
    },
    premiumSURAmount(): string {
      return formatCurrencyFloat(get(this.policyInquiryData, "SURAmt", "-"));
    },
    getTotalPolicyFee(): number {
      return parseFloat(get(this.policyInquiryData, "PolicyFee"));
    },
    policyFeeFormatted(): string {
      return formatCurrencyFloat(this.getTotalPolicyFee);
    },
    getPolicyTotal(): string {
      return get(this.policyInquiryData, "policyTotalForUI");
    }
  }
});
