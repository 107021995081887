
import { get, set } from "lodash";
import LossFormFields from "@/components/lossForm/LossForm.vue";
import { IPolicyInquiry } from "@/store/modules/propertychangerequest/types";
import { makeBlobUrlFromBase64 } from "@/helpers/downloadLossForm";
import {
  propertyChangeRequestMapActions,
  propertyChangeRequestMapState
} from "@/store/modules/propertychangerequest";
import DisplayDataInTable from "@/components/DataView/DisplayDataInTable.vue";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import Help from "@/components/Help/Inquiries.vue";
import { displayXML } from "@/helpers/displayXML";
import { policyInquiryForm } from "@/forms/shared/policyInquiry";
import { FormBlock } from "@/components/FormBuilder/types";
import { getOrigin } from "@/helpers";
import PolicyCoverage from "@/views/shared/lossForm/policyInquiry/PolicyCoverage.vue";
import PolicyDeductibles from "@/views/shared/lossForm/policyInquiry/PolicyDeductibles.vue";
import PolicyEndorsementCoverage from "@/views/shared/lossForm/policyInquiry/PolicyEndorsementCoverage.vue";
import PolicyDiscountDescription from "@/views/shared/lossForm/policyInquiry/PolicyDiscountDescription.vue";
import PolicyTotal from "@/views/shared/lossForm/policyInquiry/PolicyTotal.vue";
import PropertySchedulePremium from "@/views/shared/lossForm/policyInquiry/PropertySchedulePremium.vue";
import PolicyMortgages from "@/views/shared/lossForm/policyInquiry/PolicyMortgages.vue";
import ScheduledProperty from "@/views/shared/lossForm/policyInquiry/ScheduledProperty.vue";
export type policyInquiryResponseType = "VS" | "VR" | "";
import VueWithMixins from "@/helpers/mixins";
import InquiryMixin from "./InquiryMixin";
export default VueWithMixins(InquiryMixin).extend({
  name: "policyInquiry",
  components: {
    LossFormFields,
    DisplayDataInTable,
    CustomAlert,
    Help,
    PolicyCoverage,
    PolicyDeductibles,
    PolicyEndorsementCoverage,
    PolicyDiscountDescription,
    PolicyTotal,
    PolicyMortgages,
    PropertySchedulePremium,
    ScheduledProperty
  },
  data(): {
    hasValues: boolean;
    formType: string;
    title: string;
    titleOfSubmitButton: string;
    policyData: IPolicyInquiry;
    loading: boolean;
    formFields: {
      policyNumber: string;
      effectiveDate: string;
      companyPrefix: string;
      companyNumber: number;
      category?: string;
      responseType?: string;
    };
    errorMessage: string;
    showAlert: boolean;
    loadingText: string;
    tempPolicyInfo: Record<string, any>;
  } {
    return {
      hasValues: false,
      formType: "policyInquiry",
      title: "Policy Inquiry",
      titleOfSubmitButton: "View Policy Inquiry",
      policyData: {
        companyNumber: 0,
        companyPrefix: "",
        policyNumber: "",
        site: ""
      },
      loading: false,
      formFields: {
        policyNumber: 0,
        effectiveDate: "",
        companyPrefix: "",
        companyNumber: 0,
        category: "claims",
        responseType: ""
      } as any,
      errorMessage: "",
      showAlert: false,
      loadingText: "",
      tempPolicyInfo: {}
    };
  },
  created() {
    const policyInfo: Record<string, any> = {
      companyNumber: this.$route.query.companyNumber,
      policyNumber: this.$route.query.policyNumber,
      policyPrefix: this.$route.query.policyPrefix,
      effectiveDate: this.$route.query.effectiveDate
    };
    const {
      companyNumber = "",
      policyNumber = "",
      policyPrefix = "",
      effectiveDate = new Date()
    } = policyInfo;
    if (companyNumber && policyNumber && policyPrefix) {
      this.tempPolicyInfo = {
        companyNumber: Number(companyNumber),
        policyNumber,
        policyPrefix,
        effectiveDate
      };
    }
  },
  computed: {
    underwriterDetails(): any {
      return get(this.$getCurrentUser.agencyData, "underWriterData", {});
    },
    ...propertyChangeRequestMapState(["editing"]),
    formIsValid(): boolean {
      return (
        !!this.formFields.companyPrefix &&
        !!this.formFields.companyNumber &&
        !!this.formFields.policyNumber
      );
    },
    userSecondaryActions(): any[] {
      const actions = [{ title: "Cancel", command: "cancel" }];
      if (this.$isCurrentUserAdmin) {
        actions.push(
          { title: "View Response XML", command: "responseXML" },
          { title: "View Sending XML", command: "sendingXML" }
        );
      }
      if (this.hasValues) {
        actions.push({
          title: "Print",
          command: "print"
        });
      }
      if (this.$isCurrentUserAdmin) {
        actions.push({
          title: "Policy Inquiry Activities",
          command: "policy-inquiry-activities"
        });
      }
      return actions;
    },

    policyInfoData(): Record<string, any> {
      return this.editing || {};
    },
    topActionButtonSecondary(): { text: string; key: string; subItems: any[] } {
      return {
        text: "Actions",
        key: "actions",
        subItems: this.userSecondaryActions
      };
    },
    policyType(): string {
      return get(this.editing, "PolicyType", "");
    },
    hasPropertySchedulePremium(): boolean {
      return Boolean(parseInt(get(this.editing, "PropSchedPrem", "0")) > 0);
    },
    policyInquiryFormSchema(): FormBlock[] {
      return policyInquiryForm();
    }
  },
  methods: {
    ...propertyChangeRequestMapActions([
      "getPolicyInquiry",
      "printPolicyInquiry"
    ]),
    clickPolicyInfoPrimaryButton() {
      this.$nextTick(() => {
        this.toolbarSelectItem("view");
      });
    },
    handleFormFields(filedData: any) {
      let { key, value } = filedData;
      set(this.formFields, key, value);
      // this.formFields[`${key}`] = value;
    },
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "view":
          this.getPolicyInquiryData();
          break;
        case "responseXML":
          this.getPolicyInquiryData("VR");
          break;
        case "sendingXML":
          this.getPolicyInquiryData("VS");
          break;
        case "cancel":
          this.$router.push("/").catch(() => {});
          break;
        case "print":
          this.doPrintPolicyInquiry();
          break;
        case "policy-inquiry-activities":
          this.$router.push("/policy-inquiry/activities");
          break;
      }
    },
    formatDate(date: string) {
      if (date && date.includes("-")) {
        let dateArray = date.split("-");
        let year = dateArray[0];
        let month = dateArray[1];
        let day = dateArray[2];

        return `${month}/${day}/${year}`;
      }
      return date;
    },
    async doPrintPolicyInquiry(): Promise<any> {
      const newTab = window.open(
        `${getOrigin()}/loading-preview/noMessage`,
        "_blank"
      );
      this.loadingText = "Printing Policy Inquiry. Please wait...";
      this.loading = true;
      try {
        const fileURL = await this.printPolicyInquiry(this.editing);
        if (fileURL) {
          const url = makeBlobUrlFromBase64(fileURL.document);
          if (newTab) {
            newTab.location.href = url;
          }
        } else {
          newTab?.close();
          this.$appNotifyError(
            "Error printing policy inquiry. Try again later"
          );
        }
      } catch (error) {
        newTab?.close();
        this.$appNotifyError("Error printing policy inquiry. Try again later");
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    async getPolicyInquiryData(
      responseType: policyInquiryResponseType = ""
    ): Promise<void> {
      try {
        if (this.hasValues) {
          this.hasValues = false;
        }
        this.showAlert = false;
        this.loading = true;
        this.loadingText = "Loading Policy Details. Please wait...";
        if (
          !this.formFields.companyPrefix ||
          !this.formFields.companyNumber ||
          !this.formFields.effectiveDate ||
          !this.formFields.policyNumber
        ) {
          this.errorMessage = "Please fill all required form fields. ";
          this.showAlert = true;
          this.loading = false;
          return;
        }
        this.formFields.responseType = responseType;
        const response = await this.getPolicyInquiry(this.formFields);
        if (response) {
          if (responseType === "VS" || responseType === "VR") {
            const rawXML = response;
            displayXML(rawXML);
          } else {
            this.hasValues = true;
          }
          this.formFields.responseType = "";
        } else {
          this.errorMessage =
            "Error fetching policy information. Try again later";
          this.showAlert = true;
        }
      } catch (error) {
        this.loading = false;
        const message = Array.isArray(error.message)
          ? error.message.join("\n")
          : error.message ||
            "Error fetching policy information. Try again later";

        this.errorMessage = message;
        const messageToSearch = "Call Your Underwriter";
        const regex = new RegExp(messageToSearch, "i");
        const defaultMessage = "contact your Underwriter via ";
        let messageToReplace = "";

        if (regex.test(this.errorMessage) && this.underwriterDetails) {
          const hasUnderwriterEmail = this.underwriterDetails.email;
          const hasUnderwriterPhone =
            this.underwriterDetails.phone &&
            this.underwriterDetails.phone.primary;
          if (hasUnderwriterEmail && !hasUnderwriterPhone) {
            messageToReplace = `${defaultMessage} email on ${this.underwriterDetails.email}`;
          } else if (!hasUnderwriterEmail && hasUnderwriterPhone) {
            messageToReplace = `${defaultMessage} Phone on ${this.underwriterDetails.phone.primary}`;
          } else if (hasUnderwriterEmail && hasUnderwriterPhone) {
            messageToReplace = `${defaultMessage} email on ${this.underwriterDetails.email} OR phone on ${this.underwriterDetails.phone.primary}`;
          }
          this.errorMessage = this.errorMessage.replace(
            regex,
            messageToReplace || messageToSearch
          );
        }
        this.showAlert = true;
        this.$bugSnagClient.notify(error);
        this.hasValues = false;
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    clearData() {
      const { lossFormFields } = this.$refs;
      (lossFormFields as any).resetFields();
      this.hasValues = false;
    }
  }
});
